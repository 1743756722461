import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  FaSearch,
  FaUser,
  FaUsers,
  FaMapMarkerAlt,
  FaHandshake,
  FaCheckCircle,
  FaCalendarAlt,
  FaUserPlus,
  FaDollarSign,
  FaArrowUp,
  FaBriefcase,
  FaStar,
  FaArrowRight,
  FaArrowDown,
  FaTasks,
  FaClipboardCheck,
  FaInstagram,
  FaFacebook,
  FaUserTie,
} from "react-icons/fa";
import axiosInstance from "../axiosInstance";
import logo from "../images/logo1.png"; // Update the path to your user image logo
import company1 from "../images/company1.png"; // Replace with your actual image path
import company2 from "../images/company2.png"; // Replace with your actual image path
import company3 from "../images/company3.png"; // Replace with your actual image path
import main from "../images/main.jpg"; // Replace with your actual image path
import skilledmatesgif from "../images/skilledmates.gif"; // Replace with your actual image path

const HomePage = () => {
  const navigate = useNavigate();
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const [news, setNews] = useState([]);
  const [progressWidth, setProgressWidth] = useState(0);
  const progressDuration = 7000; // Duration for the progress bar to fill (7 seconds)
  const [showButton, setShowButton] = useState(false);
  const [newUsers, setNewUsers] = useState([]);
  const [newUsersImages, setNewUsersImages] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [RandonNumber, setRandonNumber] = useState(0);

  const sponsors = [
    {
      id: 1,
      name: "Entry Education",
      logo: "https://entryeducation.edu.au/wp-content/uploads/2021/05/entry-education250x97px.png", // Replace with sponsor logo URL
      description:
        "Enrol and study your real estate course online or your finance and mortgage broking course without mountains of paperwork, stress or unachievable deadlines.",
      website: "https://entryeducation.edu.au/",
    },
    {
      id: 2,
      name: "LJ Hooker ",
      logo: "https://ljh-public.s3.amazonaws.com/ljh-logo-black_152x30.svg",
      description:
        "Real estate agents in Adelaide Metro.",
      website: "https://stpeters.ljhooker.com.au/",
    },
    {
      id: 3,
      name: "Raywhite ",
      logo: "https://au.cdn.realty.com.au/agency/5CU9POlc1",
      description:
        "Join Australia's leading real estate group, with nearly 12,000 property professionals and 1,000 businesses across 11 countries.",
      website: "https://www.raywhite.com/",
    }
  ];

  //Generate random number between 50-200
 

  useEffect(() => {
    setRandonNumber(Math.floor(Math.random() * (200 - 50 + 1)) + 50);
  }, []);

  useEffect(() => {
    const fetchNewUsersImages = async () => {
      try {
        const response = await axiosInstance.get("/api/getNewUsersImages");
        setNewUsersImages(response.data.usersImages);
        setTotalUsers(response.data.totalUsers);
      } catch (error) {
        console.error("Error fetching new users images:", error);
      }
    };

    fetchNewUsersImages();
  }, []);

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await axiosInstance.get("/api/news");
        setNews(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching news:", error);
      }
    };

    fetchNews();
  }, []);

  useEffect(() => {
    const fetchNewUsers = async () => {
      try {
        const response = await axiosInstance.get("/api/newusers");
        setNewUsers(response.data);
      } catch (error) {
        console.error("Error fetching new users:", error);
      }
    };

    fetchNewUsers();
  }, []);

  useEffect(() => {
    const fetchVisitorCount = async () => {
      try {
        const response = await axiosInstance.get("/api/visitorcount");
      } catch (error) {
        console.error("Error fetching visitor count:", error);
      }
    };

    fetchVisitorCount();
  }, []);

  useEffect(() => {
    // Reset progress bar when news or currentCardIndex changes
    setProgressWidth(0);
    const interval = setInterval(() => {
      setProgressWidth((prevProgress) => {
        if (prevProgress >= 100) {
          clearInterval(interval);
          handleNextNews();
          return 100;
        }
        return prevProgress + 1;
      });
    }, progressDuration / 100);

    return () => clearInterval(interval);
  }, [news, currentCardIndex]);

  const handleNextNews = () => {
    setCurrentCardIndex((prevIndex) => (prevIndex + 1) % news.length);
  };

  const handleFindWorkClick = () => {
    navigate("/find-work");
  };

  const handlePostTaskClick = () => {
    navigate("/tasks/view/allTasks");
  };

  const handleCardClick = (url) => {
    navigate(url); // Navigate to the specified URL
  };

  const handleFindWorkerClick = () => {
    navigate("/find-worker");
  };

  // Detect scroll position
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  // Scroll to top function
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const testimonials = [
    {
      name: "John Doe",
      message:
        "Skilled Mates helped me find a job quickly, and it was exactly what I was looking for. Highly recommended!",
      rating: 5,
    },
    {
      name: "Jane Smith",
      message:
        "I was able to find workers at short notice, and the process was seamless. Great platform!",
      rating: 4,
    },
    {
      name: "Robert Brown",
      message:
        "Amazing experience! I managed to pick up extra shifts in my area thanks to Skilled Mates.",
      rating: 5,
    },
  ];

  const [currentTestimonialIndex, setCurrentTestimonialIndex] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTestimonialIndex(
        (prevIndex) => (prevIndex + 1) % testimonials.length
      );
    }, 5000); // Change testimonials every 5 seconds

    return () => clearInterval(interval);
  }, [testimonials.length]);

  return (
    <div className="bg-gray-100 overflow-x-hidden">
      {showButton && (
        <button
          onClick={scrollToTop}
          className="fixed bottom-5 right-5 p-3 rounded-full shadow-lg hover:opacity-90 transition-opacity"
          style={{
            background: "linear-gradient(to right, #6A5ACD, #00BFFF)", // Gradient color
            color: "white",
            zIndex: 1000,
          }}
        >
          <FaArrowUp size={20} /> {/* FontAwesome arrow up icon */}
        </button>
      )}
      {/* Hero Section */}
      <section className="flex flex-col items-center justify-center bg-white py-12 px-6 relative overflow-hidden">
        {/* Logo */}
        <div className="mb-6">
          <img
            src={main}
            alt="Skilled Mates logo"
            className="h-30 md:h-80 w-auto transition-transform duration-300 hover:scale-110"
          />
        </div>

        {/* Headline */}
        <h1 className="text-3xl  font-bold text-gray-800 text-center mb-4">
          Connect Employers with Workers, Post or Accept Tasks, <br />
          <span className="inline-block decoration-primary relative">
            <span className="relative z-10">All in one platform</span>
            <span className="absolute bg-red-100 h-1 md:h-2 -bottom-0.5 inset-x-0"></span>
          </span>
        </h1>
        <p className="text-gray-700 text-center mb-8 max-w-3xl">
          Skilled Mates is your go-to platform for finding skilled workers or
          getting jobs done quickly and easily. Find skilled workers by weekly
          availability, post a task, hire a worker, or find work—hassle-free.
        </p>

        {/* Main Actions */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-5xl">
          {/* Employers */}
          <div className="bg-gray-100 p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 transform hover:scale-105">
            <h3 className="text-xl font-semibold mb-2 text-green-600">
              Employers
            </h3>
            <p className="text-gray-600 mb-4 text-sm">
              Post jobs and access a pool of qualified workers based on skills
              and availability. Only a monthly subscription required. No
              commission from workers' pay, you will talk to workers directly.
            </p>
            <button
              onClick={handleFindWorkerClick}
              className="bg-green-500 text-white font-bold py-2 px-4 rounded-lg hover:bg-green-600 transition duration-300"
            >
              Find Workers
            </button>
          </div>

          {/* Workers */}
          <div className="bg-gray-100 p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 transform hover:scale-105">
            <h3 className="text-xl font-semibold mb-2 text-blue-600">
              Workers
            </h3>
            <p className="text-gray-600 mb-4 text-sm">
              Find flexible job opportunities and get paid for your skills—free
              for workers. Contact employers directly and get hired quickly.
            </p>
            <button
              onClick={handleFindWorkClick}
              className="bg-blue-500 text-white font-bold py-2 px-4 rounded-lg hover:bg-blue-600 transition duration-300"
            >
              Find Work
            </button>
          </div>

          {/* Tasks */}
          <div className="bg-gray-100 p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 transform hover:scale-105">
            <h3 className="text-xl font-semibold mb-2 text-purple-600">
              Tasks
            </h3>
            <p className="text-gray-600 mb-4 text-sm">
              Get tasks done by professionals quickly. Both employers and
              workers can post a task, offer on a task, and get paid directly
              through Skilled Mates. Only charge 10% commission on the agreed
              offer from tasker once task is done.
            </p>
            <button
              onClick={handlePostTaskClick}
              className="bg-purple-500 mt-2 text-white font-bold py-2 px-4 rounded-lg hover:bg-purple-600 transition duration-300"
            >
              Post a Task
            </button>
            <button
              onClick={handlePostTaskClick}
              className="bg-red-600 ml-1 mt-2 text-white font-bold py-2 px-4 rounded-lg hover:bg-orange-600 transition duration-300"
            >
              Find a Task
            </button>
          </div>
        </div>

        {/* Trust Signals */}
        {/* <div className="mt-10 text-center">
          <p className="text-gray-600 font-semibold mb-4">
            Trusted by Top Employers
          </p>
          <div className="flex justify-center space-x-8">
            <img
              src={company1}
              alt="Company 1"
              className="h-16 pb-2 w-auto transition-transform duration-300 hover:scale-110"
            />
            <img
              src={company2}
              alt="Company 2"
              className="h-10 w-auto transition-transform duration-300 hover:scale-110"
            />
            <img
              src={company3}
              alt="Company 3"
              className="h-12 w-auto transition-transform duration-300 hover:scale-110"
            />
          </div>
        </div> */}
        <div className="mt-10 ">
          {newUsersImages.length > 0 ? (
            <>
              <div className="flex flex-col md:flex-row justify-center items-center gap-3">
                <div className="-space-x-4 avatar-group justify-start">
                  {newUsersImages.map((user, index) => (
                    <div
                      key={index}
                      className="avatar w-11 h-11 border-2 border-gray-500 "
                    >
                      <img
                        alt="User"
                        className="rounded-full"
                        src={user.profileImage}
                      />
                    </div>
                  ))}
                </div>
              </div>

              <p className="text-gray-600 font-semibold mt-2 text-center flex items-center justify-center">
                <span className="font-semibold text-gray-950 mr-1 text-lg">
                  {" "}
                  { RandonNumber}{" "}
                </span>
                {"  "}
                users are active now
                <span className="relative flex h-3 w-3 mt-1 ml-2">
                  <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75"></span>
                  <span className="relative inline-flex rounded-full h-3 w-3 bg-green-500"></span>
                </span>
              </p>
            </>
          ) : (
            <div className="flex justify-center items-center h-32">
              <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-16 w-16"></div>
            </div>
          )}
        </div>
      </section>

      {/* News/Auto-Rotating Cards Section */}
      {news.length > 0 && (
        <section className="py-10 bg-gray-50">
          <div className="max-w-5xl mx-auto text-center">
            <h2 className="text-3xl font-bold text-gray-800 mb-8">
              What's New on Skilled Mates
            </h2>
            {news.length > 0 && (
              <div
                className="bg-white p-6 rounded-lg shadow-lg max-w-xl mx-auto transition-transform duration-300 transform hover:scale-105 cursor-pointer"
                onClick={() =>
                  (window.location.href = news[currentCardIndex].takeUserTo)
                }
              >
                <h3 className="text-gray-500  text-xl font-bold mb-4">
                  {news[currentCardIndex]?.description}
                </h3>
                <div className="flex justify-center items-center mb-2">
                  <FaMapMarkerAlt size={18} className="text-red-500 mr-2" />
                  <span className="text-gray-600">
                    {news[currentCardIndex].location}
                  </span>
                </div>
                <div className="flex justify-between items-center">
                  {/* <span className="text-sm text-gray-500">
                    Posted on:{" "}
                    {new Date(
                      news[currentCardIndex].postedAt
                    ).toLocaleDateString()}
                  </span> */}
                </div>
                {/* Progress Line */}
                <div className="relative h-1 mt-4 bg-gray-200 rounded-lg">
                  <div
                    className="absolute top-0 left-0 h-1 bg-blue-400 transition-all rounded-lg"
                    style={{
                      width: `${progressWidth}%`,
                    }}
                  ></div>
                </div>
              </div>
            )}
          </div>
        </section>
      )}

      {newUsers.length > 0 && (
        <>
          <p className="text-gray-800 text-lg font-semibold mt-4 text-center">
            Welcome our new users
          </p>
          {/* <div className="flex overflow-hidden mt-2">
            <div className="whitespace-nowrap flex space-x-2 md:space-x-4 animate-slide">
              {newUsers.map((user, index) => (
                <span
                  key={index}
                  className="flex items-center bg-gradient-to-r from-green-400 to-teal-500 text-white px-3 py-2 rounded-full text-xs md:text-sm font-semibold shadow-md transition-transform transform hover:scale-105 hover:shadow-xl"
                >
                  <FaUser className="mr-2" />
                  {user.name}
                </span>
              ))}
            </div>
          </div> */}
          <div className="flex overflow-hidden mt-2 mb-2">
            <div className="whitespace-nowrap flex space-x-2 md:space-x-4 animate-slide">
              {newUsers.map((user, index) => (
                <div
                  key={index}
                  className="flex items-center bg-gradient-to-r from-green-400 to-teal-500 text-white px-4 py-1 rounded-full text-xs md:text-sm font-semibold shadow-2xl shadow-gray-600 transition-transform transform hover:scale-105 hover:shadow-xl"
                  style={{ maxWidth: "fit-content" }} // Dynamically adjust the green part width
                >
                  {user.profileImage ? (
                    <img
                      src={user.profileImage}
                      alt={user.name}
                      className="mr-2 w-8 h-8 rounded-full"
                    />
                  ) : (
                    <FaUser className="mr-2" />
                  )}
                  <span
                    className="text-xs truncate" // Add truncate for long names
                    title={user.name} // Show full name on hover
                  >
                    {user.name}
                  </span>
                </div>
              ))}
            </div>
          </div>

          <style>
            {`
        @keyframes slide {
          0% { transform: translateX(0%); } /* Start just outside the viewport */
          100% { transform: translateX(-100%); } /* Move to fully outside the viewport */
        }
        
        .animate-slide {
          animation: slide 60s linear infinite; /* Keep the duration at 60 seconds */
        }
      `}
          </style>
        </>
      )}
     
     
    
      <section className="py-12 bg-gray-50">
        <div className="max-w-7xl mx-auto text-center">
          <h2 className="text-3xl font-bold text-gray-800 mb-8">
            New Task Feature 🎉
          </h2>
          <p className="text-lg text-gray-600 mb-12">
            Post tasks, accept tasks, and get paid directly through Skilled
            Mates. We are the lowest commission platform for tasks with only 10%
            commission on the agreed offer.
          </p>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {/* Step 1: Post a Task */}
            <div className="bg-white p-6 rounded-lg shadow-lg text-center hover:shadow-2xl transition-shadow duration-300 transform hover:scale-105">
              <div className="mb-4">
                <FaTasks
                  size={40}
                  className="mx-auto text-blue-500 transition-transform duration-300 hover:rotate-12"
                />
              </div>
              <h3 className="text-xl font-semibold text-gray-700 mb-2">
                1. Post a Task
              </h3>
              <p className="text-gray-600">
                Need something done? Create a task post.
              </p>
            </div>

            {/* Step 2: Review and Accept Applications */}
            <div className="bg-white p-6 rounded-lg shadow-lg text-center hover:shadow-2xl transition-shadow duration-300 transform hover:scale-105">
              <div className="mb-4">
                <FaClipboardCheck
                  size={40}
                  className="mx-auto text-green-500 transition-transform duration-300 hover:rotate-12"
                />
              </div>
              <h3 className="text-xl font-semibold text-gray-700 mb-2">
                2. Review and Accept
              </h3>
              <p className="text-gray-600">
                Browse offers and choose the best worker for your task with just
                one click.
              </p>
            </div>

            {/* Step 3: Complete Task and Get Paid */}
            <div className="bg-white p-6 rounded-lg shadow-lg text-center hover:shadow-2xl transition-shadow duration-300 transform hover:scale-105">
              <div className="mb-4">
                <FaDollarSign
                  size={40}
                  className="mx-auto text-yellow-500 transition-transform duration-300 hover:rotate-12"
                />
              </div>
              <h3 className="text-xl font-semibold text-gray-700 mb-2">
                3. Complete
              </h3>
              <p className="text-gray-600">
                Worker will Complete the task, mark it done, and receive payment
                seamlessly within Skilled Mates.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-gray-50 py-16">
        <div className="max-w-5xl mx-auto text-center">
          <h2 className="text-4xl font-bold text-gray-800 mb-8">
            Pricing Plans
          </h2>
          <p className="text-gray-600 mb-12">
            Affordable plans designed to help you find the right match, whether
            you're a job seeker or an employer.
          </p>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {/* Free for Workers */}
            <div
              className="bg-white p-8 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 transform hover:scale-105 cursor-pointer"
              onClick={() => navigate("/register")}
            >
              <FaUsers className="text-blue-500 text-6xl mb-4 mx-auto transition-transform duration-300 hover:rotate-12" />
              <h3 className="text-2xl font-semibold mb-4">For Workers</h3>
              <p className="text-gray-600 mb-6">
                Skilled Mates is{" "}
                <span className="text-green-500 font-bold">
                  completely free
                </span>{" "}
                for workers. Sign up, find jobs, find tasks and connect with
                employers without any cost.
              </p>
              <p className="text-4xl font-bold text-gray-800">Free</p>
              <div className="mt-6">
                <button className="bg-blue-500 text-white px-6 py-3 rounded-full font-semibold hover:bg-blue-600 transition-colors duration-300">
                  Get Started
                </button>
              </div>
            </div>

            {/* $50/month for Employers */}
            <div
              className="bg-white p-8 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 transform hover:scale-105 cursor-pointer"
              onClick={() => navigate("/register")}
            >
              <FaBriefcase className="text-green-500 text-6xl mb-4 mx-auto transition-transform duration-300 hover:rotate-12" />
              <h3 className="text-2xl font-semibold mb-4">For Employers (Small Organisation)</h3>
              <p className="text-gray-600 mb-6">
                Employers can access a large pool of workers and post jobs for a
                small fee of{" "}
                <span className="text-yellow-500 font-bold">$25 per month</span>
                . Find workers quickly and efficiently.
              </p>
              <p className="text-4xl font-bold text-gray-800">$25</p>
              <p className="text-gray-500 text-sm">Per Month</p>
              <div className="mt-6">
                <button className="bg-blue-500 text-white px-6 py-3 rounded-full font-semibold hover:bg-blue-600 transition-colors duration-300">
                  Get Membership now
                </button>
              </div>
            </div>

            <div
              className="bg-white p-8 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 transform hover:scale-105 cursor-pointer"
              onClick={() => navigate("/register")}
            >
              <FaBriefcase className="text-green-500 text-6xl mb-4 mx-auto transition-transform duration-300 hover:rotate-12" />
              <h3 className="text-2xl font-semibold mb-4">For Employers (large Organisation)</h3>
              <p className="text-gray-600 mb-6">
                Employers can access a large pool of workers and post jobs for a
                small fee of{" "}
                <span className="text-yellow-500 font-bold">$50 per month</span>
                . Find workers quickly and efficiently. Post unlimited jobs. Contact unlimited workers.
              </p>
              <p className="text-4xl font-bold text-gray-800">$50</p>
              <p className="text-gray-500 text-sm">Per Month</p>
              <div className="mt-6">
                <button className="bg-blue-500 text-white px-6 py-3 rounded-full font-semibold hover:bg-blue-600 transition-colors duration-300">
                  Get Membership now
                </button>
              </div>
            </div>

            {/* $50/month for Employers */}
            <div
              className="bg-white p-8 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 transform hover:scale-105 cursor-pointer"
              onClick={() => navigate("/register")}
            >
              <FaTasks className="text-green-500 text-6xl mb-4 mx-auto transition-transform duration-300 hover:rotate-12" />
              <h3 className="text-2xl font-semibold mb-4">Tasks</h3>
              <p className="text-gray-600 mb-6">
                Post tasks and connect with skilled workers for your
                needs—whether it’s repairs, cleaning, or specialised jobs.
                Workers are charged a small commission{" "}
                <span className="font-semibold">
                  only after the task is successfully completed
                </span>
                , ensuring fairness and reliability.
              </p>
              <p className="text-4xl font-bold text-gray-800">Low Commission</p>
              <p className="text-gray-500 text-sm">We charge</p>
              <p className="text-4xl font-bold text-gray-800">10%</p>
              <p className="text-gray-500 text-sm">
                to the tasker after the job is done.
              </p>
              <p className="text-gray-500 text-sm">You will get paid by us.</p>
              <div className="mt-6">
                <button className="bg-green-500 text-white px-6 py-3 rounded-full font-semibold hover:bg-green-600 transition-colors duration-300">
                  Post a Task
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-12 bg-gray-50">
        <div className="max-w-7xl mx-auto text-center">
          <h2 className="text-3xl font-bold text-gray-800 mb-8">
            How It Works
          </h2>
          <p className="mb-6 text-lg text-gray-700">
            Skilled Mates focuses on three key groups: 👔 Employers, 🛠️ Workers,
            and 📋 Tasks.
          </p>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {/* Employer Section */}
            <div
              onClick={() => navigate("/find-worker")}
              className="bg-white p-6 rounded-lg shadow-lg text-center hover:shadow-2xl transition-shadow duration-300 transform hover:scale-105"
            >
              <div className="mb-4">
                <FaBriefcase
                  size={40}
                  className="mx-auto text-blue-500 transition-transform duration-300 hover:rotate-12"
                />
              </div>
              <h3 className="text-xl font-semibold text-gray-700 mb-2">
                For Employers 👔
              </h3>
              <p className="text-gray-600">
                - Sign up to get started. <br />
                - Get skilledmates membership. <br />
                - Find skilled workers with availability. <br />
                - Post a job if no workers match your criteria. Worker will
                contact you <br />- Connect directly to negotiate pay and hire.
              </p>
            </div>

            {/* Worker Section */}
            <div
              onClick={() => navigate("/find-work")}
              className="bg-white p-6 rounded-lg shadow-lg text-center hover:shadow-2xl transition-shadow duration-300 transform hover:scale-105"
            >
              <div className="mb-4">
                <FaUserTie
                  size={40}
                  className="mx-auto text-green-500 transition-transform duration-300 hover:rotate-12"
                />
              </div>
              <h3 className="text-xl font-semibold text-gray-700 mb-2">
                For Workers 🛠️
              </h3>
              <p className="text-gray-600">
                - Sign up <br />
                - Find work <br />
                - Contact employers directly <br />- Land the job that's right
                for you.
              </p>
            </div>

            {/* Tasks Section */}
            <div
              onClick={() => navigate("/tasks/view/allTasks")}
              className="bg-white p-6 rounded-lg shadow-lg text-center hover:shadow-2xl transition-shadow duration-300 transform hover:scale-105"
            >
              <div className="mb-4">
                <FaTasks
                  size={40}
                  className="mx-auto text-yellow-500 transition-transform duration-300 hover:rotate-12"
                />
              </div>
              <h3 className="text-xl font-semibold text-gray-700 mb-2">
                Tasks 📋
              </h3>
              <p className="text-gray-600">
                - Sign up <br />
                - Add payment & payout details <br />
                - Post or offer on tasks <br />- Complete tasks and get paid
                securely by Skilled Mates.
              </p>
            </div>
          </div>

          {/* Learn More Button */}
          {/* <div className="mt-8">
      <button
        onClick={() => navigate("/how-it-works")}
        className="bg-blue-500 text-white px-6 py-2 rounded hover:bg-blue-600 transition"
      >
        Learn More
      </button>
    </div> */}
        </div>
      </section>
      <section className="flex flex-col items-center justify-center bg-white py-12 px-6 relative overflow-hidden">
        {/* Logo and Branding */}
        <div className="mb-6">
          <img
            src={skilledmatesgif}
            alt="FoodOrderoo logo"
            className="w-auto h-auto md:h-100 transition-transform duration-300 hover:scale-110"
          />
        </div>
      </section>

      {/* Features Section */}
      <section className="bg-white py-16">
        <div className="max-w-5xl mx-auto text-center">
          <h2 className="text-3xl font-bold text-gray-800 mb-8">
            Why Choose Skilled Mates?
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {/* Wide Talent Pool */}
            <div className="bg-gray-100 p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 transform hover:scale-105">
              <FaUsers className="text-blue-500 text-5xl mb-4 mx-auto transition-transform duration-300 hover:rotate-12" />
              <h3 className="text-xl font-semibold mb-2">Wide Talent Pool</h3>
              <p className="text-gray-600">
                Access a diverse range of skilled workers for any job
                requirement. Whether you're looking for temporary, part-time, or
                specialised workers, Skilled Mates has you covered.
              </p>
            </div>

            {/* Quick Connections */}
            <div className="bg-gray-100 p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 transform hover:scale-105">
              <FaHandshake className="text-green-500 text-5xl mb-4 mx-auto transition-transform duration-300 hover:rotate-12" />
              <h3 className="text-xl font-semibold mb-2">Quick Connections</h3>
              <p className="text-gray-600">
                Connect with job seekers or employers within minutes. Use our
                advanced filters and tools to find the right match instantly.
              </p>
            </div>

            {/* Location-Based Search */}
            <div className="bg-gray-100 p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 transform hover:scale-105">
              <FaMapMarkerAlt className="text-red-500 text-5xl mb-4 mx-auto transition-transform duration-300 hover:rotate-12" />
              <h3 className="text-xl font-semibold mb-2">
                Location-Based Search
              </h3>
              <p className="text-gray-600">
                Find work or hire workers based on your location. Skilled Mates
                makes it easy to find local talent or jobs.
              </p>
            </div>
            <div className="bg-gray-100 p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 transform hover:scale-105">
              <FaDollarSign className="text-yellow-500 text-5xl mb-4 mx-auto transition-transform duration-300 hover:rotate-12" />
              <h3 className="text-xl font-semibold mb-2">
                Affordable & Fair Pricing
              </h3>
              <p className="text-gray-600">
                Skilled Mates is free for workers to use. For employers, there
                are no high upfront costs—just a simple subscription-based model
                that allows you to post jobs and find workers without breaking
                the bank.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-gray-50 py-8 px-4 md:px-8">
        <div className="max-w-4xl mx-auto text-center">
          <h2 className="text-2xl md:text-3xl font-bold text-gray-800 mb-4">
            💸 Earn upto $50 with Skilled Mates!
          </h2>
          <p className="text-gray-700 mb-6">
            Refer an employer to join Skilled Mates and enjoy our membership,
            and you'll earn upto $50! Depends on the membership they choose.. It's simple:
          </p>
          <div className="text-left md:text-center bg-gradient-to-r from-green-400 to-teal-500 text-white p-6 rounded-lg shadow-lg space-y-4">
            <ul className="list-decimal list-inside">
              <li>Get someone to sign up as an employer on Skilled Mates.</li>
              <li>Ensure they purchase the Skilled Mates Membership.</li>
              <li>
                Fill out our{" "}
                <a
                  href="https://www.skilledmates.com.au/feedback"
                  className="underline font-semibold"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  feedback form
                </a>{" "}
                with:
                <ul className="list-disc list-inside mt-2 ml-4">
                  <li>The employer's name or email.</li>
                  <li>Your email and phone number in the message.</li>
                </ul>
              </li>
            </ul>
          </div>
          <p className="text-gray-600 mt-6">
            <span className="font-bold">Terms and Conditions:</span> Employers
            must not cancel their membership within the first month for you to
            be eligible for the reward.
          </p>
          <p className="text-gray-700 mt-4">
            Once verified, we will contact you to arrange the payout details.
            Start sharing and earning today!
          </p>
        </div>
      </section>
      <section className="py-12 bg-blue-50">
        <div className="max-w-7xl mx-auto text-center">
          {/* Headline */}
          <h2 className="text-4xl font-bold text-blue-700 mb-4">
            Join the Growing Community on Skilled Mates
          </h2>

          {/* Subheadline */}
          <p className="text-xl text-gray-700 mb-8">
            Skilled Mates is growing fast! joined us today. Find your next
            opportunity or the perfect candidate now—don't get left behind!
          </p>

          {/* Key Points */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-8">
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h3 className="text-xl font-semibold text-gray-700 mb-2">
                For Workers
              </h3>
              <p className="text-gray-600">
                Looking for your next casual or temporary job? Create your
                profile and get matched with opportunities today!
              </p>
            </div>

            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h3 className="text-xl font-semibold text-gray-700 mb-2">
                For Employers
              </h3>
              <p className="text-gray-600">
                Need staff fast? Post your job, set your requirements, and get
                instant access to available workers nearby.
              </p>
            </div>

            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h3 className="text-xl font-semibold text-gray-700 mb-2">
                Easy & Instant Matches
              </h3>
              <p className="text-gray-600">
                Whether you're hiring or job hunting, Skilled Mates connects you
                instantly based on real-time availability.
              </p>
            </div>
          </div>

          {/* Call-to-Action Button */}
          <div>
            <button
              onClick={() => navigate("/register")}
              className="bg-blue-600 text-white px-6 py-3 rounded-lg text-lg hover:bg-blue-700 transition duration-300"
            >
              Sign Up Today and Get Started!
            </button>
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      <section className="py-12 bg-white">
        <div className="max-w-7xl mx-auto text-center">
          <h2 className="text-3xl font-bold text-gray-800 mb-8">
            What Our Users Say
          </h2>
          <div className="bg-gray-100 p-6 rounded-lg shadow-lg max-w-3xl mx-auto">
            <p className="text-xl text-gray-700 mb-4">
              "{testimonials[currentTestimonialIndex].message}"
            </p>
            <p className="text-lg font-semibold text-gray-800 mb-4">
              - {testimonials[currentTestimonialIndex].name}
            </p>
            <div className="flex justify-center">
              {[...Array(testimonials[currentTestimonialIndex].rating)].map(
                (_, index) => (
                  <FaStar key={index} className="text-yellow-400" />
                )
              )}
              {[...Array(5 - testimonials[currentTestimonialIndex].rating)].map(
                (_, index) => (
                  <FaStar key={index} className="text-gray-300" />
                )
              )}
            </div>
          </div>
        </div>
      </section>
       <section className="bg-gray-50 py-10">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-gray-800 text-center mb-8">
            Our Weekly Sponsors
          </h2>
          <p className="text-center text-gray-600 mb-6">
            Support our featured businesses and discover great services!
          </p>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {sponsors.map((sponsor) => (
              <div
                key={sponsor.id}
                className="bg-white rounded-lg shadow-lg  overflow-hidden transition-transform transform hover:scale-105 "
              >
                <img
                  src={sponsor.logo}
                  alt={sponsor.name}
                  className="w-full h-32 object-contain"
                />
                <div className="p-6">
                  <h3 className="text-xl font-semibold text-gray-800">
                    {sponsor.name}
                  </h3>
                  <p className="text-gray-600 text-sm mt-2">
                    {sponsor.description}
                  </p>
                  <a
                    href={sponsor.website}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mt-4 inline-block bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 transition-colors"
                  >
                    Visit Website
                  </a>
                </div>
              </div>
            ))}
          </div>
          <div className="text-center mt-8">
            <p className="text-gray-600 mb-4">
              Want to be our weekly sponsor? Fill out the feedback form to get
              started!
            </p>
            <a
              href="/feedback" // Replace with the actual link to your feedback form 
              rel="noopener noreferrer"
              className="inline-block bg-green-600 text-white px-4 py-2 rounded-md hover:bg-green-700 transition-colors"
            >
              Be Our Weekly Sponsor
            </a>
          </div>
        </div>
      </section> 
      {/* Contact Section */}
      <section className="bg-blue-600 text-white py-16">
        <div className="max-w-5xl mx-auto text-center">
          <h2 className="text-3xl font-bold mb-8">Get in Touch</h2>
          <p className="mb-4">Have any questions?</p>
          <p className="mb-2">
            <Link to="/feedback" className="underline">
              Message us
            </Link>
          </p>
          <span>or</span>
          <p className="mt-2">
            <a
              href="mailto:sukhi@skilledmates.com.au"
              className="underline text-white"
            >
              Email us
            </a>
          </p>
          <div className="mt-8">
            <p className="mb-4 text-lg font-semibold">
              Stay up to date on our social media:
            </p>
            <div className="flex justify-center space-x-6">
              <a
                href="https://www.facebook.com/profile.php?id=61565426317581"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center space-x-2"
              >
                <FaFacebook className="w-6 h-6 text-white" />
                <span className="underline">Facebook</span>
              </a>
              <a
                href="https://www.instagram.com/skilledmates.com.au/"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center space-x-2"
              >
                <FaInstagram className="w-6 h-6 text-white" />
                <span className="underline">Instagram</span>
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HomePage;
